import logo from './logo.svg';
import './App.css';
import Emoji from 'a11y-react-emoji'

function App() {
  return (
    <div className="App">
      <header className="App-header">  
        <p>
        <h1><Emoji symbol="👋"/> <span></span>Hello there! Any enquiry please contact us</h1>
        </p>
        <p>
          <h3>
        <Emoji symbol="📧"/> <span></span>
        <a
          className="App-link"
          href="mailto: enquiry@onesolutionstation.com"
          target="_blank"
          rel="noopener noreferrer"
        >
            enquiry@onesolutionstation.com
        </a>
        </h3>
        </p>
      </header>
    </div>
  );
}

export default App;
